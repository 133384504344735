<template>
  <el-dialog :visible="visible"
    :title="$t('users.reset-mfa-dialog')"
    :before-close="handleClose">
    <div v-loading="loading"
      :element-loading-text="$t('general.saving')">
      <p>{{ $t('users.reset-mfa-dialog-desc') }}</p>
      <mfa-qr v-if="visible"
        v-model="secret" />
      <el-form ref="mfaResetForm"
        :model="mfaResetForm"
        :rules="mfaResetRules"
        label-width="250px"
        @submit.native.prevent="resetMfa">
        <el-form-item :label="$t('users.mfa-token')"
          prop="oneTimePassword">
          <el-input v-model.trim="mfaResetForm.oneTimePassword"
            :placeholder="$t('general.token')"
            name="oneTimePassword"
            data-testid="reset-mfa-modal-otp-input"
            autocomplete="off" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button data-testid="reset-mfa-modal-cancel-button"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        data-testid="reset-mfa-modal-reset-button"
        @click="resetMfa">
        {{ $t('users.reset-mfa-dialog') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import sdk from '@megaport/api-sdk'
import { validateForm } from '@/utils/form.js'
import MfaQr from '@/components/authentication/mfa/MfaQr.vue'

// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  name: 'ResetMfaModal',

  components: { 'mfa-qr': MfaQr },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      secret: '',
      mfaResetForm: {
        oneTimePassword: null,
      },

      mfaResetRules: {
        oneTimePassword: { required: true, message: this.$t('validations.required', { thing: this.$t('general.token') }), trigger: 'blur' },
      },
    }
  },

  computed: {
    ...mapState('Auth', { auth: state => state.data }),
  },


  methods: {
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),

    resetForm() {
      this.mfaResetForm = {
        oneTimePassword: '',
      }

      if (this.$refs.mfaResetForm) {
        this.$refs.mfaResetForm.clearValidate()
      }
    },

    setVisible(newValue) {
      this.$emit('update:visible', newValue)
      this.resetForm()
    },

    handleClose(done) {
      this.setVisible(false)
      done()
    },

    async resetMfa() {
      try {
        await validateForm(this.$refs.mfaResetForm)
      } catch {
        this.notifyBad({
          title: this.$t('validations.failed'),
          message: this.$t('validations.correct-issues'),
        })

        return
      }

      try {
        this.loading = true
        await sdk.instance
          .employee(this.auth.personId)
          .resetMfa({
            totpSecret: this.secret,
            totpCode: this.mfaResetForm.oneTimePassword,
          })

        this.notifyGood({ title: this.$t('users.reset-mfa-success') })
        this.setVisible(false)
      } catch (error) {
        console.error(error)
        this.notifyBad({
          title: this.$t('general.request-failed'),
          message: `${this.$t('users.reset-mfa-failed')}: ${error.data?.message || this.$t('general.unknown-error')}`,
        })
      } finally {
        this.loading = false
      }

    },
  },
}
</script>

<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t('users.change-email')">
    <div v-loading="savingChangeEmail"
      :element-loading-text="$t('users.changing-email')">
      <el-form ref="changeEmailForm"
        :model="changeEmailForm"
        :rules="changeEmailRules"
        label-width="150px">
        <el-form-item prop="passwordEmail"
          :label="$t('general.password')">
          <el-input v-model.trim="changeEmailForm.passwordEmail"
            :placeholder="$t('general.password')"
            name="changeEmailPassword"
            type="password"
            autocomplete="off" />
        </el-form-item>
        <el-form-item prop="newEmail"
          :label="$t('users.new-email')">
          <el-input v-model.trim="changeEmailForm.newEmail"
            :placeholder="$t('general.email')"
            name="newEmail"
            autocomplete="email" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        @click="submitChangeEmail">
        {{ $t('general.save') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
/**
 * This component is the old change email modal which requires the user
 * to put in their password rather than get a verification token via email.
 * A feature flag controls whether this one or the new one is shown.
 * It should be getting removed and replaced with the new one in the future.
 */
import { mapMutations } from 'vuex'
import sdk from '@megaport/api-sdk'

export default {
  name: 'ChangeEmailModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      savingChangeEmail: false,
      changeEmailForm: {
        passwordEmail: '',
        newEmail: '',
      },
      changeEmailRules: {
        passwordEmail: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('general.password') }), trigger: 'blur' },
          { min: 6, message: this.$tc('validations.min-length', 6, { min: 6 }), trigger: 'blur' },
        ],
        newEmail: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('general.email') }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
      },
    }
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    /**
     * Set modal visibility and reset form
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$refs['changeEmailForm'].resetFields()
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Submit form with verification code from new email
     */
    submitChangeEmail() {
      this.$refs.changeEmailForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }
        this.savingChangeEmail = true

        const payload = {
          password: this.changeEmailForm.passwordEmail,
          newEmail: this.changeEmailForm.newEmail,
        }
        sdk.instance
          .profile()
          .changeEmail(payload)
          .then(() => {
            this.notifyGood({
              title: this.$t('users.verification-email'),
              message: this.$t('users.verification-message'),
            })
          })
          .catch(err => {
            this.notifyBad({
              title: this.$t('general.error-updating', { thing: this.$t('general.email') }),
              message: err.data?.message || err || this.$t('general.unknown-error'),
            })
          })
          .finally(() => {
            this.savingChangeEmail = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-form > .el-form-item:first-child .el-form-item__content {
    display: flex;

    .el-button {
      margin-left: 1rem;
    }
  }

  .confirmation-modal {
    width: fit-content;

    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>

<template>
  <div class="main-content">
    <!-- New change email modal -->
    <change-email-modal :visible.sync="showChangeEmail" />

    <!-- Old change email modal -->
    <change-email-modal-old :visible.sync="showChangeEmailOld" />

    <email-preferences-modal v-if="showEmailPrefs"
      :visible.sync="showEmailPrefs"
      :party-id="profile.partyId"
      :email-preferences="emailPreferences" />

    <change-password-modal :visible.sync="showChangePassword"
      :profile="profile"
      :user-profile-form="userProfileForm" />

    <mfa-modal :visible.sync="showMfa" />

    <reset-mfa-modal :visible.sync="showResetMfaModal" />

    <h2 class="text-align-center mb-2">
      {{ $t('menu.user-profile') }}
    </h2>

    <el-form ref="userProfileForm"
      :model="userProfileForm"
      :rules="userProfileRules"
      label-width="150px">
      <div v-loading="savingUserProfile"
        class="form-box"
        :element-loading-text="$t('users.saving-user-profile')">
        <el-form-item :label="$t('users.username')">
          <div class="display-data"
            data-testid="profileUsername">
            {{ profile.username }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('general.first-name')"
          prop="firstName">
          <el-input v-model="userProfileForm.firstName"
            data-testid="profileFirstName"
            name="firstName" />
        </el-form-item>
        <el-form-item :label="$t('general.last-name')"
          prop="lastName">
          <el-input v-model="userProfileForm.lastName"
            data-testid="profileLastName"
            name="lastName" />
        </el-form-item>
        <el-form-item prop="phone">
          <template #label>
            {{ $t('general.phone') }}
            <el-tooltip placement="top"
              data-testid="phoneTooltip"
              :content="$t('validations.phone-format')"
              :open-delay="500">
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-input v-model="userProfileForm.phone"
            data-testid="profilePhone"
            data-demo="+61 7 12341234 ext 4321"
            placeholder="+61 7 40309999"
            name="phone" />
        </el-form-item>
        <el-form-item :label="$t('general.email')">
          <div class="display-data"
            data-testid="profileEmail">
            {{ profile.email }}
          </div>
        </el-form-item>
        <el-form-item v-if="isRenderableRole"
          :label="$t('users.user-role')">
          <div class="display-data"
            data-testid="profileUserRole">
            {{ profile.position }}
          </div>
        </el-form-item>

        <div class="text-align-right">
          <el-button data-testid="userFormRevertButton"
            @click="revertProfileForm">
            {{ $t('general.revert') }}
          </el-button>
          <el-button data-testid="userFormSaveButton"
            type="primary"
            @click="submitProfile">
            {{ $t('general.save') }}
          </el-button>
        </div>
      </div>
    </el-form>

    <hr class="mt-3 mx-0 mb-2-5">
    <div class="button-row">
      <el-button v-if="!isSocialLogin"
        name="showChangeEmail"
        data-testid="changeEmailBtn"
        @click="toggleChangeEmail">
        {{ $t('users.change-email') }}
      </el-button>
      <el-button v-if="showEmailPreferences"
        name="showEmailPreferences"
        data-testid="emailPreferencesBtn"
        @click="showEmailPrefs = true">
        {{ $t('users.email-preferences') }}
      </el-button>
      <el-button v-if="!isSocialLogin"
        name="showChangePassword"
        data-testid="changePasswordBtn"
        @click="showChangePassword = true">
        {{ $t('users.change-password') }}
      </el-button>

      <!-- If MFA is enforced and the user has it enabled, show a fake button-tooltip to explain why they can't disable -->
      <el-tooltip v-if="company.enforceMfa && hasMfaEnabled"
        placement="top"
        :content="$t('users.mfa-enforced-disable-tooltip')">
        <!-- span required for tooltip to work when button is disabled -->
        <span>
          <el-button name="showMfa"
            class="ml-1 mr-1"
            data-testid="disabled-mfa-button"
            disabled>
            {{ mfaButtonText }}
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-button>
        </span>
      </el-tooltip>

      <!-- If MFA is optional OR enforced but not enabled, render the real button with previous logic -->
      <el-button v-else
        name="showMfa"
        data-testid="mfaBtn"
        @click="showMfa = true">
        {{ mfaButtonText }}
      </el-button>

      <el-button :disabled="!hasMfaEnabled"
        name="resetMfa"
        data-testid="reset-mfa-button"
        @click="showResetMfaModal = true">
        {{ $t('users.reset-mfa') }}
      </el-button>
    </div>
    <hr class="mt-2-5 mx-0 mb-3">

    <h3 class="text-align-center mb-2">
      {{ $t('users.account-activity') }}
    </h3>
    <el-table v-loading="loadingActivityData"
      :data="activityStore"
      :empty-text="activityDataLoadFailed ? $t('users.account-activity-failed') : $t('users.account-activity-none')"
      stripe
      max-height="500"
      :element-loading-text="$t('users.account-activity-loading')"
      class="normal-wrap">
      <el-table-column property="description"
        :label="$t('general.description')" />
      <el-table-column :label="$t('general.date')"
        width="175">
        <template #default="{row}">
          <span>{{ row.date }} {{ row.time }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { pick } from 'lodash'
import { DateTime } from 'luxon'
import { mapActions, mapGetters, mapState } from 'vuex'
import sdk from '@megaport/api-sdk'

import {
  ROLE_TYPE_COMPANY_ADMIN,
  ROLE_TYPE_TECHNICAL_ADMIN,
  ROLE_TYPE_TECHNICAL_CONTACT,
  ROLE_TYPE_FINANCE,
  ROLE_TYPE_FINANCIAL_CONTACT,
  ROLE_TYPE_READ_ONLY,
} from '@/Globals'
import ResetMfaModalComponent from '@/components/user-profile/ResetMfaModal.vue'
import MfaModalComponent from '@/components/user-profile/MfaModal.vue'
import ChangeEmailModalComponent from '@/components/user-profile/ChangeEmailModal.vue'
import ChangeEmailModalOldComponent from '@/components/user-profile/ChangeEmailModalOld.vue'
import EmailPreferencesModalComponent from '@/components/user-profile/EmailPreferencesModal.vue'
import ChangePasswordModalComponent from '@/components/user-profile/ChangePasswordModal.vue'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { PHONE_NUMBER_REGEX } from '@/validators'

export default {
  name: 'UserProfile',

  components: {
    'reset-mfa-modal': ResetMfaModalComponent,
    'mfa-modal': MfaModalComponent,
    'change-email-modal': ChangeEmailModalComponent,
    'change-email-modal-old': ChangeEmailModalOldComponent,
    'email-preferences-modal': EmailPreferencesModalComponent,
    'change-password-modal': ChangePasswordModalComponent,
  },

  inject: ['isFeatureEnabled'],

  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (vm.isManagedContext) {
        next(resolveServicesPage())
      }
    })
  },

  data() {
    return {
      userProfileForm: {
        firstName: '',
        lastName: '',
        phone: '',
      },
      userProfileRules: {
        firstName: { required: true, message: this.$t('validations.required', { thing: this.$t('general.first-name') }), trigger: 'blur' },
        lastName: { required: true, message: this.$t('validations.required', { thing: this.$t('general.last-name') }), trigger: 'blur' },
        phone: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.phone-number') }), trigger: 'blur' },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('validations.phone-format'), trigger: 'blur' },
        ],
      },
      savingUserProfile: false,

      activityStore: null,
      activityDataLoadFailed: false,
      loadingActivityData: false,

      emailPreferences: {
        newsletter: true,
        promotions: true,
      },

      // Modals visibility
      showChangeEmail: false,
      showChangeEmailOld: false,
      showEmailPrefs: false,
      showChangePassword: false,
      showMfa: false,
      showResetMfaModal: false,
    }
  },

  computed: {
    ...mapState('Notifications', ['eventLog']),
    ...mapState('UserProfile', ['profile']),
    ...mapState('Company', { company: state => state.data }),
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', ['hasMfaEnabled', 'isManagedAccount', 'isDistributorAccount', 'hasFeatureFlag']),

    mfaButtonText() {
      return this.hasMfaEnabled ? this.$t('users.disable-mfa') : this.$t('users.enable-mfa')
    },

    showEmailPreferences() {
      return !this.isManagedAccount && !this.isDistributorAccount
    },

    isRenderableRole() {
      const renderableRole = [ROLE_TYPE_COMPANY_ADMIN, ROLE_TYPE_TECHNICAL_ADMIN, ROLE_TYPE_TECHNICAL_CONTACT, ROLE_TYPE_FINANCE, ROLE_TYPE_FINANCIAL_CONTACT, ROLE_TYPE_READ_ONLY]
      return renderableRole.includes(this.profile.position)
    },

    enableNewChangeEmail() {
      return this.hasFeatureFlag('oauth_password')
    },

    isSocialLogin() {
      return JSON.parse(localStorage.getItem('_isSSOAccount'))
    },
  },

  watch: {
    profile() {
      this.initializeProfileForm()
    },
    eventLog: {
      immediate: true,
      // watch the global eventlog and update user activity
      handler() {
        // Sync Activity only if user is logged in
        if (this.$store.state.Auth.loggedIn) {
          this.syncActivity()
        }
      },
    },
  },

  created() {
    this.currentOverlay = this.$route.path.split('/')[2] || 'activity'
  },

  mounted() {
    this.initializeProfileForm()
  },

  methods: {
    ...mapActions('UserProfile', ['postProfile']),
    ...mapActions('Users', ['syncUsers']),
    initializeProfileForm() {
      this.userProfileForm = pick(this.profile, ['firstName', 'lastName', 'phone'])

      // Get email preferences values
      this.emailPreferences = {
        newsletter: this.profile.newsletter ?? true,
        promotions: this.profile.promotions ?? true,
      }
    },

    syncActivity() {
      this.loadingActivityData = true
      sdk.instance
        .profile()
        .activity()
        .then(res => {
          this.activityStore = res.map(activity => {
            let description = activity.description.split(':')
            description.shift()

            return {
              description: description.join(),
              date: DateTime.fromMillis(activity.eventDate).toFormat('LLL dd, y'),
              time: DateTime.fromMillis(activity.eventDate).toLocaleString({ hour: 'numeric', minute: '2-digit', hour12: true }).toUpperCase(),
            }
          })
          this.loadingActivityData = false
        })
        .catch(e => {
          // TODO: Handle errors better
          const props = {
            title: this.$t('general.load-failed'),
            message: `${this.$t('users.activity-data-load-fail')}:${e.data?.message || e || this.$t('general.unknown-error')}`,
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          this.loadingActivityData = false
          this.activityDataLoadFailed = true
        })
    },

    revertProfileForm() {
      this.initializeProfileForm()
      this.$refs.userProfileForm.clearValidate()
    },

    submitProfile() {
      this.$refs.userProfileForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }
        const saveData = { ...this.profile, ...this.userProfileForm }
        this.savingUserProfile = true

        // This is to work around a BE bug
        // These MFA fields are only able to be updated via the new MFA specific endpoints,
        // but the BE would update via this endpoint if included in the payload
        delete saveData.mfaEnabled
        delete saveData.requireTotp

        this.postProfile(saveData)
          .then(() => {
            this.syncUsers()
            this.savingUserProfile = false
          })
          .catch(() => {
            // capturing the error in store
            this.savingUserProfile = false
          })
      })
    },

    /**
     * Toggle which change email modal to show depending on feature flag
     */
    toggleChangeEmail() {
      if (this.enableNewChangeEmail) {
        this.showChangeEmail = true
      } else {
        this.showChangeEmailOld = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.form-box {
  margin: 20px;
  padding: 20px;
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.display-data {
  font-weight: 300;
}

.button-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>

<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t('users.email-preferences')">
    <div v-loading="savingEmailPrefs"
      :element-loading-text="$t('users.saving-email-preferences')">
      <el-form ref="emailPreferencesForm"
        :model="emailPreferencesForm">
        <el-form-item prop="newsletter">
          <el-checkbox v-model="emailPreferencesForm.newsletter">
            {{ $t('users.receive-newsletter') }}
          </el-checkbox>
        </el-form-item>
        <el-form-item prop="promotions">
          <el-checkbox v-model="emailPreferencesForm.promotions">
            {{ $t('users.receive-promotional-material') }}
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button data-testid="emailPrefCancelBtn"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button data-testid="emailPrefSaveBtn"
        type="primary"
        @click="submitEmailPref">
        {{ $t('general.save') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'

export default {
  name: 'EmailPreferencesModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    partyId: {
      type: Number,
      required: false,
      default: NaN,
    },
    emailPreferences: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      emailPreferencesForm: {
        newsletter: this.emailPreferences.newsletter,
        promotions: this.emailPreferences.promotions,
      },
      savingEmailPrefs: false,
    }
  },

  methods: {
    ...mapActions('UserProfile', ['syncProfile']),
    ...mapMutations('Notifications', ['notifyGood']),
    /**
     * Set modal visibility
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    submitEmailPref() {
      this.savingEmailPrefs = true
      sdk.instance
        .employee(this.partyId)
        .update(this.emailPreferencesForm)
        .then(() => {
          this.notifyGood({
            title: this.$t('users.profile-saved'),
            message: this.$t('users.email-change-success'),
          })
          this.syncProfile()
          this.savingEmailPrefs = false
          this.setVisible(false)
        })
        .catch(e => {
          // TODO: Handle errors better
          captureSentryError(e)
          this.savingUserProfile = false
          const props = {
            title: this.$t('users.save-failed'),
            message: `${this.$t('users.save-email-preference-failed')}: ${e.data?.message || e || this.$t('general.unknown-error')}`,
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          this.savingEmailPrefs = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

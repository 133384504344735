<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t('users.change-password')">
    <div v-loading="savingChangePassword"
      :element-loading-text="$t('users.changing-password')">
      <el-form ref="changePasswordForm"
        :model="changePasswordForm"
        :rules="changePasswordRules"
        label-width="220px">
        <el-form-item prop="oldPassword"
          :label="$t('users.old-password')">
          <el-input v-model.trim="changePasswordForm.oldPassword"
            :placeholder="$t('users.old-password')"
            name="oldPassword"
            type="password"
            autocomplete="off" />
        </el-form-item>

        <el-form-item :label="$t('users.new-password')"
          prop="newPassword">
          <el-input v-model.trim="changePasswordForm.newPassword"
            :placeholder="$t('users.new-password')"
            name="newPassword"
            type="password"
            autocomplete="off">
            <template #append>
              <el-popover placement="top"
                :title="$t('authentication.password-must-include')"
                width="300"
                trigger="hover">
                <div class="text-align-left">
                  <ul class="pl-2">
                    <li>{{ $t('authentication.at-least-8-char') }}</li>
                    <li>{{ $t('authentication.at-least-one-number') }}</li>
                    <li>{{ $t('authentication.at-least-one-symbol') }}</li>
                    <li>{{ $t('authentication.at-least-one-lower-case-letter') }}</li>
                    <li>{{ $t('authentication.at-least-one-upper-case-letter') }}</li>
                  </ul>
                </div>
                <span slot="reference"
                  :class="requiredResetPassStrength.class">
                  <i class="el-icon-info color-info fs-1-6rem line-height-initial"
                    aria-hidden="true" />
                </span>
              </el-popover>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('users.retype-new-password')"
          prop="confirmPassword">
          <el-input v-model.trim="changePasswordForm.confirmPassword"
            :placeholder="$t('users.retype-new-password')"
            name="confirmPassword"
            type="password"
            autocomplete="off" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button name="cancelChangePassword"
        data-testid="passwordCancelBtn"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        name="saveChangePassword"
        data-testid="passwordSaveBtn"
        @click="submitChangePassword">
        {{ $t('general.save') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { validatePassword } from '@/validators.js'
import { passStrength } from '@/utils/passwordStrength.js'

export default {
  name: 'ChangePasswordModal',

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    profile: {
      type: Object,
      required: true,
    },
    userProfileForm: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      changePasswordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      changePasswordRules: {
        oldPassword: { required: true, message: this.$t('validations.required', { thing: this.$t('users.old-password') }), trigger: 'blur' },
        newPassword: { required: true, validator: this.validateNewPassword, trigger: 'blur' },
        confirmPassword: { required: true, validator: this.validateMatchingPasswords, trigger: 'blur' },
      },
      savingChangePassword: false,
    }
  },

  computed: {
    requiredResetPassStrength() {
      if (this.changePasswordForm.newPassword.length && this.changePasswordForm.oldPassword === this.changePasswordForm.newPassword) {
        return {
          rating: this.$t('authentication.very-weak'),
          class: 'color-danger',
          feedback: {
            warning: this.$t('validations.password-different'),
          },
          score: 0,
        }
      }
      return passStrength(this.changePasswordForm.newPassword)
    },
  },

  methods: {
    ...mapActions('UserProfile', ['postPassword']),
    /**
     * Set modal visibility and reset form
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$refs['changePasswordForm'].resetFields()
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    validateNewPassword(rule, value, callback) {
      if (!value || value.length === 0) {
        callback(this.$t('validations.new-password'))
      } else if (this.changePasswordForm.oldPassword === value) {
        callback(this.$t('validations.password-different'))
      } else {
        const passwordAnalysis = this.requiredResetPassStrength
        validatePassword(rule, value, callback, passwordAnalysis)
      }
    },
    validateMatchingPasswords(_rule, value, callback) {
      if (!value || value.length === 0) {
        callback(this.$t('validations.new-password-retype'))
      } else if (this.changePasswordForm.newPassword !== value) {
        callback(this.$t('validations.new-password-different'))
      } else {
        callback()
      }
    },
    submitChangePassword() {
      this.$refs.changePasswordForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }

        const payload = {
          newPassword: this.changePasswordForm.newPassword,
          oldPassword: this.changePasswordForm.oldPassword,
        }

        this.savingChangePassword = true
        this.postPassword(payload)
          .then(() => {
            this.savingChangePassword = false
            this.setVisible(false)
          })
          .catch(() => {
            this.savingChangePassword = false
          })
      })
    },
  },
}
</script>

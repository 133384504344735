<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('users.change-email')">
    <!-- Confirmation dialog -->
    <el-dialog :visible.sync="showConfirmation"
      custom-class="confirmation-modal"
      append-to-body>
      <auth-card :heading-text="$t('authentication.verify-email-heading')"
        :btn-text="$t('authentication.verify-email-button')"
        :show-close-btn="true"
        :show-card-only="true"
        :btn-disabled="resentEmailRequested"
        @close="showConfirmation = !showConfirmation"
        @btn-click="requestVerificationCode(false)">
        <template #description>
          <p class="font-weight-500">
            {{ $t('authentication.verification-code-sent') }}
          </p>
          <p>{{ $t('authentication.verify-email-description') }}</p>
        </template>
      </auth-card>
    </el-dialog>
    <div v-loading="savingChangeEmail"
      :element-loading-text="$t('users.changing-email')">
      <p>{{ $t($t('authentication.change-email-desc')) }}</p>
      <p><strong>{{ $t('authentication.change-email-warning') }}</strong></p>
      <el-form ref="changeEmailForm"
        :model="changeEmailForm"
        :rules="changeEmailRules"
        label-width="180px">
        <el-form-item prop="newEmail"
          :label="$t('users.new-email')">
          <el-input v-model.trim="changeEmailForm.newEmail"
            :placeholder="$t('general.email')"
            name="newEmail"
            type="email"
            autocomplete="email"
            data-testid="newEmail" />
          <el-button type="primary"
            size="small"
            :disabled="disableVerificationButton"
            data-testid="verificationBtn"
            @click="requestVerificationCode(true)">
            {{ $t($t('authentication.get-verification-code')) }}
          </el-button>
        </el-form-item>
        <el-form-item prop="verificationCode"
          :label="$t('authentication.verification-code')">
          <el-input v-model.trim="changeEmailForm.verificationCode"
            :placeholder="$t('authentication.verification-code')"
            name="verificationCode"
            autocomplete="off"
            :disabled="!verificationRequested" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button data-testid="cancelBtn"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        data-testid="saveBtn"
        @click="submitChangeEmail">
        {{ $t('general.save') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import AuthCard from '@/components/ui-components/AuthCard.vue'
import sdk from '@megaport/api-sdk'

export default {
  name: 'ChangeEmailModal',

  components: {
    'auth-card': AuthCard,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      savingChangeEmail: false,
      disableVerificationButton: true,
      verificationRequested: false,
      showConfirmation: false,
      resentEmailRequested: false,
      changeEmailForm: {
        newEmail: '',
        verificationCode: '',
      },
      changeEmailRules: {
        newEmail: { required: true, validator: this.validateNewEmail, trigger: 'change' },
        // More validations may be needed here once I know what the code is supposed to look like
        verificationCode: { required: true, message: this.$t('validations.required', { thing: this.$t('authentication.verification-code') }), trigger: 'blur' },
      },
    }
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    ...mapMutations('Auth', ['logout']),
    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$refs['changeEmailForm'].resetFields()
      this.verificationRequested = false
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Validate new email field and enable get verification button when valid
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateNewEmail(_rule, value, callback) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!value || value.length === 0) {
        this.disableVerificationButton = true
        callback(this.$t('validations.required', { thing: this.$t('general.email') }))
      } else if (!emailRegex.test(this.changeEmailForm.newEmail)) {
        this.disableVerificationButton = true
        callback(this.$t('validations.email-invalid'))
      } else {
        this.disableVerificationButton = false
        callback()
      }
    },
    /**
     * Request verification code via email when
     * clicking get verification code button
     */
    requestVerificationCode(initialRequest) {
      const payload = {
        newEmail: this.changeEmailForm.newEmail,
      }

      sdk.instance
        .profile()
        .changeEmail(payload)
        .then(() => {
          if (initialRequest) {
            this.notifyGood({
              title: this.$t('users.verification-email'),
              message: this.$t('users.verification-message'),
            })
            this.verificationRequested = true
            this.showConfirmation = true
            this.resentEmailRequested = false
          } else {
            this.notifyGood({
              title: this.$t('authentication.verify-email-resent-successful'),
            })
            this.resentEmailRequested = true
          }
          this.savingChangeEmail = false
        })
        .catch(err => {
          if (initialRequest) {
            this.notifyBad({
              title: this.$t('general.error-updating', { thing: this.$t('general.email') }),
              message: err.data?.message || err || this.$t('general.unknown-error'),
            })
          } else {
            this.notifyBad({
              title: this.$t('authentication.verify-email-resent-unsuccessful'),
            })
          }
          this.savingChangeEmail = false
        })
    },
    /**
     * Submit form with verification code from new email
     */
    submitChangeEmail() {
      this.$refs.changeEmailForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }

        sdk.instance
          .profile()
          .verifyEmailUpdate(this.changeEmailForm.verificationCode)
          .then(() => {
            this.savingChangeEmail = false
            this.notifyGood({
              title: this.$t('general.success-updating', { thing: this.$t('general.email') }),
            })
            // Access token becomes invalid with new email so log out the user and populate username field with new email
            localStorage.setItem('_username', this.changeEmailForm.newEmail)
            this.logout()
            this.$router.push('/login', () => { /* empty */ })
          })
          .catch(err => {
            if (err.status === 403) {
              // Email already used by someone else
              this.notifyBad({
                title: this.$t('authentication.verify-email-taken'),
              })
            } else if (err.status === 409) {
              // Confirmation code is wrong
              this.notifyBad({
                title: this.$t('authentication.verification-code-wrong'),
              })
            } else if (err.status === 410) {
              // Expired confirmation code
              this.notifyBad({
                title: this.$t('authentication.verification-code-expired'),
              })
            } else {
              this.notifyBad({
                title: this.$t('authentication.failed-confirm'),
              })
            }
            this.savingChangeEmail = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-form > .el-form-item:first-child .el-form-item__content {
    display: flex;

    .el-button {
      margin-left: 1rem;
    }
  }

  .confirmation-modal {
    width: fit-content;

    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>

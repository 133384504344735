<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="dialogTitle">
    <div v-loading="loading"
      :element-loading-text="$t('general.saving')">
      <el-form ref="mfaForm"
        :model="mfaForm"
        :rules="mfaRules"
        label-width="250px"
        @submit.native.prevent="hasMfaEnabled ? disableMfa() : enableMfa()">
        <!-- Disable MFA content -->
        <template v-if="hasMfaEnabled">
          <p v-for="i in 2"
            :key="i">
            {{ $t(`users.mfa-warning-${i}`) }}
          </p>
        </template>
        <!-- Enable MFA content -->
        <template v-else>
          <mfa-qr v-if="!hasMfaEnabled"
            v-model="secret" />

          <el-form-item :label="$t('users.mfa-token')"
            prop="oneTimePassword">
            <el-input v-model.trim="mfaForm.oneTimePassword"
              :placeholder="$t('general.token')"
              name="oneTimePassword"
              data-testid="mfa-modal-otp-input"
              autocomplete="off" />
          </el-form-item>
        </template>
      </el-form>
    </div>
    <template #footer>
      <el-button data-testid="mfa-modal-cancel-button"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>

      <el-button v-if="hasMfaEnabled"
        type="primary"
        data-testid="mfa-modal-disable-button"
        @click="disableMfa">
        {{ $t('users.disable-mfa-dialog') }}
      </el-button>

      <el-button v-else
        type="primary"
        data-testid="mfa-modal-enable-button"
        @click="enableMfa">
        {{ $t('users.enable-mfa-dialog') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import sdk from '@megaport/api-sdk'
import { validateForm } from '@/utils/form.js'
import MfaQr from '@/components/authentication/mfa/MfaQr.vue'

// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  name: 'MfaModal',

  components: { 'mfa-qr': MfaQr },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

  },

  data() {
    return {
      loading: false,
      secret: '',
      mfaForm: {
        oneTimePassword: null,
      },
      mfaRules: {
        oneTimePassword: { required: true, message: this.$t('validations.required', { thing: this.$t('general.token') }), trigger: 'blur' },
      },
      authFailCount: 0,
    }
  },

  computed: {
    ...mapState('UserProfile', ['profile']),
    ...mapState('Auth', { auth: state => state.data }),
    ...mapGetters('Auth', ['hasMfaEnabled', 'hasFeatureFlag']),
    dialogTitle() {
      return this.hasMfaEnabled ? this.$t('users.disable-mfa-dialog') : this.$t('users.enable-mfa-dialog')
    },
  },

  methods: {
    ...mapActions('Auth', ['logout']),
    ...mapActions('Users', ['syncUsers']),
    ...mapMutations('Auth', ['setMfaEnabled']),
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),

    resetForm() {
      this.mfaForm = {
        oneTimePassword: '',
      }

      if (this.$refs.mfaForm) {
        this.$refs.mfaForm.clearValidate()
      }
    },

    setVisible(newValue) {
      this.$emit('update:visible', newValue)
      this.resetForm()
    },

    handleClose(done) {
      this.setVisible(false)
      done()
    },

    // This repeats in enableMfa() FF on and FF off paths
    enableMfaSuccess() {
      this.setMfaEnabled()
      this.notifyGood({ title: this.$t('users.enable-mfa-success') })
      this.setVisible(false)
    },

    // This repeats in enableMfa() FF on and FF off paths
    enableMfaFailure(error) {
      this.notifyBad({
        title: this.$t('general.request-failed'),
        message: `${this.$t('authentication.enable-mfa-failed')}: ${error.data?.message || this.$t('general.unknown-error')}`,
      })
    },

    async enableMfa() {
      // Form validation block
      try {
        await validateForm(this.$refs.mfaForm)
      } catch {
        this.notifyBad({
          title: this.$t('validations.failed'),
          message: this.$t('validations.correct-issues'),
        })

        return
      }

      try {
        this.loading = true
        const employeeId = this.auth.personId
        await sdk.instance
          .employee(employeeId)
          .enableMfa({
              totpSecret: this.secret,
              totpCode: this.mfaForm.oneTimePassword,
            })


        // Need to refresh userlist to keep in sync
        await this.syncUsers()
        this.enableMfaSuccess()
      } catch (error) {
        this.enableMfaFailure(error)
      } finally {
        this.loading = false
      }
    },

    // This repeats in disableMfa() the FF on and FF off paths
    disableMfaSuccess() {
      this.notifyGood({
        title: this.$t('users.mfa-disabled'),
      })

      this.setVisible(false)
      this.logout()
    },

    // This repeats in disableMfa() the FF on and FF off paths
    disableMfaFailure() {
      this.notifyBad({
        title: this.$t('users.saving-user-profile-error'),
        message: this.$t('users.disable-mfa-fail'),
      })
    },

    async disableMfa() {
      try {
        this.loading = true
        const employeeId = this.auth.personId
        await sdk.instance
          .employee(employeeId)
          .disableMfa()

        this.disableMfaSuccess()
      } catch (error) {
        this.disableMfaFailure(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
